import { BaseApiModel } from "@/model/common/BaseApiModel";
import { Transform, Type } from "class-transformer";
import moment from 'moment-timezone'
import { EntityTypeEnum } from "../enums/EntityTypeEnum";
import { FeeTypeEnum } from "../enums/FeeTypeEnum";
import { Candidacy, CandidacyStatusEnum } from "./Candidacy";
import { City, Country, Province, Region } from "./Domain";

export class Entity extends BaseApiModel {
    type: EntityTypeEnum = null;
    business_name: string = null;
    description: string = null;
    country_id: number = null;
    region_id: number = null;
    province_id: number = null;
    city_id: number = null;
    foreing_province: string = null;
    zip: string = null;
    address: string = null;
    lng: number = null;
    lat: number = null;
    business_country_id: number = null;
    business_region_id: number = null;
    business_province_id: number = null;
    business_city_id: number = null;
    business_foreing_province: string = null;
    business_zip: string = null;
    business_address: string = null;
    pec: string = null;
    email: string = null;
    phone: string = null;
    internal_ref: string = null;
    phone_internal_ref: string = null;
    fiscal_code: string = null;
    vat_code: string = null;
    sdi: string = null;
    iban: string = null;
    bank_name: string = null;

    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).tz('Europe/Rome').toDate() : null,
        { toClassOnly: true }
    )
    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).startOf('day').toISOString() : null,
        { toPlainOnly: true }
    )
    start_contract_at: Date;

    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).tz('Europe/Rome').toDate() : null,
        { toClassOnly: true }
    )
    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).endOf('day').toISOString() : null,
        { toPlainOnly: true }
    )
    end_contract_at: Date;

    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).tz('Europe/Rome').toDate() : null,
        { toClassOnly: true }
    )
    @Transform(
        ({ value, obj }) => moment(value, true).isValid() ? moment(value, true).endOf('day').toISOString() : null,
        { toPlainOnly: true }
    )
    apply_commissions_at: Date;

    is_active: boolean = false;
    fee_type: FeeTypeEnum | string = null;
    fee: number = 0;

    country?: Country;
    region?: Region;
    province?: Province;
    city?: City;

    business_country?: Country;
    business_region?: Region;
    business_province?: Province;
    business_city?: City;

    note?: string;

    days_filter_available: number = 0;

    isContractActive() {
        const now = new Date().toISOString();
        
        if (!this.start_contract_at && !this.end_contract_at) return false;
        if (!(this.start_contract_at instanceof Date) || !(this.end_contract_at instanceof Date)) return false;
        if ((!this.end_contract_at || moment(this.end_contract_at).endOf('day').toISOString() > now) && (!this.start_contract_at || moment(this.start_contract_at).startOf('day').toISOString() <= now)) return true;
 
        return false;
    }

    candidacy_status?: CandidacyStatusEnum;

    @Type(t => Candidacy)
    candidacy?: Candidacy;
}