import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete
} from "@components";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { ProviderServicesRoutesEnum } from "../../router/providerPortfolioServices";
import { ProviderService } from "@/model/api/Provider";
import { providerServiceBackofficeService } from "@services/providerService.service";
import { authStore } from "@/modules/auth/store";
import Dialog from "primevue/dialog";
import { Service, ServiceCategory } from "@/model/api/Service";
import { providerService } from "@services/provider.service";
import { FilterMatchMode } from "primevue/api";

@Options({
  components: {
    DynamicTable,
    Dialog,
    MyAutocomplete
  }
})
export default class PortfolioServicesListPage extends Vue {
  readonly stateTableKey = 'PortfolioServicesListPage';

  isAddDialogVisible = false;

  model: ProviderService = null;

  allServices: Service[] = [];
  filteredCategories: ServiceCategory[] = [];

  isLoadingServices = false;

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters: any = this.getNewFiltersSchema();

  get filterConfiguredOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('provider.portfolio.detail.config_status_complete_label'), value: true },
      { name: this.$t('provider.portfolio.detail.config_status_not_complete_label'), value: false },
    ]
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('generic.published'), value: true },
      { name: this.$t('generic.not_published'), value: false },
    ]
  }

  created() {
    this.filteredCategories = [{ id: null, name: this.$t('all_plur_fem_label') }];
  }

  beforeRouteLeave() {
    if (providerServiceBackofficeService?.cancelPendingRequests) {
      providerServiceBackofficeService.cancelPendingRequests();
    }
  }

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ProviderServicesRoutesEnum.PORTFOLIO_SERVICES_PREFIX)) {
      sessionStorage.removeItem('PortfolioServicesListPage');
    }
    next();
  }

  get me() {
    return authStore.getters.me;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  get service() {
    return providerServiceBackofficeService;
  }

  get filterServiceCategory() {
    return this.filteredCategories.find(c => c.id == this.filters.service_category_id.value && c.id);
  }

  goAdd() {
    this.allServices = [];
    this.isLoadingServices = true;
    providerService.getAvailableServices(this.me.entity_id).then(r => {
      this.allServices = r;
      this.isLoadingServices = false;
    });

    this.model = new ProviderService();
    this.isAddDialogVisible = true;
  }

  goToDetail(model: ProviderService) {
    this.$router.push({ name: ProviderServicesRoutesEnum.PORTFOLIO_SERVICES_EDIT, params: { serviceId: model.id } });
  }

  customIndexRequest(p) {
    return providerServiceBackofficeService.indexByProvider(this.me.entity_id, p);
  }

  applyFilters() {
    this.datatable.loadData();
  }

  doCreateNew() {
    this.$waitFor(
      async () => {
        this.model.entity_id = this.me.entity_id;
        this.model.duration = 30;
        const resp = await providerServiceBackofficeService.create(this.model);
        this.goToDetail(resp);
        this.isAddDialogVisible = false;
      },
      this.$t('generic_error')
    );
  }

  get cols() {
    return [
      {
        field: 'service_name',
        header: this.$t('generic.input_name_label')
      },
      {
        field: 'service_categories_text',
        header: this.$t('provider.portfolio.detail.service_categories_label'),
        filterField: 'service_category_id'
      },
      {
        field: 'network_name',
        header: this.$t('provider.portfolio.detail.network_label'),
      },
      {
        field: 'cost',
        header: this.$t('provider.portfolio.detail.cost_label')
      },
      {
        field: 'technicians_list',
        header: this.$t('provider.portfolio.detail.technicians_list_label'),
        filterField: 'technicians'        
      },
      {
        field: 'is_configured',
        header: this.$t('provider.portfolio.detail.config_status_label')
      },
      {
        field: 'duration',
        header: this.$t('provider.portfolio.detail.duration_label')
      },
      {
        field: 'is_active',
        header: this.$t('generic.input_state_label')
      }
    ];
  }

  getNewFiltersSchema() {
    return {
      service_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      service_category_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      network_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      cost: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      technicians: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      is_configured: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      is_active: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }
}
