import { Options, Vue } from "vue-class-component";

import {
  DynamicTable,
  MyAutocomplete
} from "@components";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { ProviderServicesRoutesEnum } from "../../router/providerPortfolioServices";
import { ProviderService } from "@/model/api/Provider";
import { providerServiceBackofficeService } from "@services/providerService.service";
import { authStore } from "@/modules/auth/store";
import Dialog from "primevue/dialog";
import { Service, ServiceCategory } from "@/model/api/Service";
import { providerService } from "@services/provider.service";
import { FilterMatchMode } from "primevue/api";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    DynamicTable,
    Dialog,
    MyAutocomplete
  }
})
export default class ServicesFeedbacksListPage extends Vue {
  readonly stateTableKey = 'ServicesFeedbacksListPage';

  isAddDialogVisible = false;

  model: ProviderService = null;

  allServices: Service[] = [];
  filteredCategories: ServiceCategory[] = [];

  isLoadingServices = false;

  get datatable(): any {
    return this.$refs.datatable;
  }

  filters: any = this.getNewFiltersSchema();

  get filterConfiguredOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('provider.portfolio.detail.config_status_complete_label'), value: true },
      { name: this.$t('provider.portfolio.detail.config_status_not_complete_label'), value: false },
    ]
  }

  get filterActiveOptions() {
    return [
      { name: this.$t('generic.all_sing_male_label'), value: null },
      { name: this.$t('generic.published'), value: true },
      { name: this.$t('generic.not_published'), value: false },
    ]
  }

  created() {
    this.filteredCategories = [{ id: null, name: this.$t('all_plur_fem_label') }];
  }

  beforeRouteLeave() {
    if (providerServiceBackofficeService?.cancelPendingRequests) {
      providerServiceBackofficeService.cancelPendingRequests();
    }
  }

  get me() {
    return authStore.getters.me;
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  get service() {
    return providerServiceBackofficeService;
  }

  get filterServiceCategory() {
    return this.filteredCategories.find(c => c.id == this.filters.service_category_id.value && c.id);
  }

  customIndexRequest(p) {
    if (this.me.role == UserRoleEnum.SUPER_ADMIN)
      return providerServiceBackofficeService.indexByProvider(0, p);
    else
      return providerServiceBackofficeService.indexByProvider(this.me.entity_id, p);
  }

  applyFilters() {
    this.datatable.loadData();
  }

  get cols() {
    let columns =  [
      {
        field: 'service_name',
        header: this.$t('generic.input_name_label'),
        filterField: 'service_name',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'service_categories_text',
        header: this.$t('provider.portfolio.detail.service_categories_label'),
        filterField: 'service_category_id',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'network_name',
        header: this.$t('provider.portfolio.detail.network_label'),
        filterField: 'network_name',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'cost',
        header: this.$t('provider.portfolio.detail.cost_label'),
        filterField: 'cost',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'technicians_list',
        header: this.$t('provider.portfolio.detail.technicians_list_label'),
        filterField: 'technicians',
        style: 'min-width: 10px; max-width: 150px;'
      },
      {
        field: 'feedback_service',
        header: this.$t('generic.input_feedback_label'),
        filterField: 'feedback_service',
        style: 'min-width: 200px;'
      }
    ];
    if (authStore.getters.userRole === UserRoleEnum.SUPER_ADMIN) {
      const posCol = [{
        field: 'provider_owner_name',
        header: this.$t('pos.portfolio.detail.provider_owner_name_label'),
        filterField: 'entity_id',
        style: 'min-width: 10px; max-width: 150px;'
      }];

      columns = posCol.concat(columns);
    }
    return columns;
  }

  getNewFiltersSchema() {
    return {
      entity_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      service_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },
      service_category_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      network_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      cost: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      technicians: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    };
  }
}
