import { BaseApiModel } from "@/model/common/BaseApiModel";
import { UserRoleEnum } from "../enums/UserRoleEnum";
import { Entity } from "./Entity";

export class User extends BaseApiModel {
    name: string = null;
    surname: string = null;
    email: string = null;
    role: UserRoleEnum = null;
    is_active: boolean = false;
    avatar_image_path_url: string = null;
    fiscal_code: string = null;
    
    entity_id: number = null;
    entity: Entity = null;
}

export class AppUser extends User {
    provinces: {id: number, name: string}[] = [];
    regions: {id: number, name: string}[] = [];
}