import { VAT } from '@/model/api/Domain';
import { Network } from '@/model/api/Network';
import { ProviderService, ProviderTechnician } from '@/model/api/Provider';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { AttachmentIdEnum, AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { authStore } from '@/modules/auth/store';
import { shortTime } from '@/utils/utils';
import { FileUploadDialog, FormPage, ServiceDuration } from '@components';
import { domainService } from '@services/domain.service';
import { networkService } from '@services/network.service';
import { providerServiceBackofficeService } from '@services/providerService.service';

import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VError
} from 'vee-validate';
import { Options } from "vue-class-component";
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    VForm,
    VField,
    VError,
    ServiceDuration,
    FileUploadDialog
  }
})
export default class PortfolioServiceEditPage extends FormPage {
  @Prop() readonly serviceId!: number;

  provider_attachment_type: AttachmentTypeEnum = AttachmentTypeEnum.GENERIC;
  pos_attachment_type: AttachmentTypeEnum = AttachmentTypeEnum.BY_PROVIDER_FOR_POS;
  exam_attachment_type: AttachmentTypeEnum = AttachmentTypeEnum.EXAM_PREP;
  attachment_id: AttachmentIdEnum = AttachmentIdEnum.PROVIDER_SERVICE;
  displayPosFileUploadDialog: boolean = false;
  displayProviderFileUploadDialog: boolean = false;
  displayExamFileUploadDialog: boolean = false;

  model: ProviderService = new ProviderService();
  attachments: IBaseApiAttachmentModel[] = [];
  posAttachments: IBaseApiAttachmentModel[] = [];
  examAttachments: IBaseApiAttachmentModel[] = [];
  vats: VAT[] = [];
  networks: Network[] = [];
  nessunNetwork: Network[] = [{
      name: 'Nessuno', note: '', is_active: false, id: 0
  }];

  isLoading = false;

  get isNew() {
    return this.serviceId == 0;
  }

  get isConfigured(): boolean {
    return this.model.cost > 0
      && !!this.model.time_from
      && !!this.model.time_to
      && !!this.model.vat_id
      && this.model.duration > 0
      && this.model.technicians?.length > 0;
      // return this.model.service_policy?.length > 0
      // && this.model.cost > 0
      // && !!this.model.time_from
      // && !!this.model.time_to
      // && !!this.model.vat_id
      // && this.model.duration > 0
      // && this.model.technicians?.length > 0;
  }

  get provincesLabel(): string {
    return this.model.service?.visibility?.filter(v => v.province).map(v => v.province.name).join(' - ') || '-';
  }

  get regionsLabel(): string {
    return this.model.service?.visibility?.filter(v => v.region).map(v => v.region.name).join(' - ') || '-';
  }

  get categoriesLabel() {
    return this.model.service.service_categories?.map(sc => sc.name).join(', ') || '-';
  }

  get providerId() {
    if (authStore.getters.userRole === UserRoleEnum.PROVIDER) {
      return authStore.getters.me?.id;
    }
  }

  isLoadingTechnicians: boolean = false;
  techniciansOptions: ProviderTechnician[] = null;

  private async loadTechnicians() {
    try {
      this.isLoadingTechnicians = true;

      this.techniciansOptions = await providerServiceBackofficeService.getAvailableTechnicians(this.model.id);
    } finally {
      this.isLoadingTechnicians = false;
    }
  }

  beforeRouteLeave(to, from, next) {
    this.checkBeforeRouteLeave(next);
  }

  async created() {
    if (this.isNew) {
      this.$router.back();
      return;
    }

    this.isLoading = true;
    try {
      this.model = await providerServiceBackofficeService.getById(this.serviceId);
      this.attachments = await providerServiceBackofficeService.getAttachments(this.serviceId, AttachmentTypeEnum.GENERIC);
      this.posAttachments = await providerServiceBackofficeService.getAttachments(this.serviceId, AttachmentTypeEnum.BY_PROVIDER_FOR_POS);
      this.examAttachments = await providerServiceBackofficeService.getAttachments(this.serviceId, AttachmentTypeEnum.EXAM_PREP);
      this.vats = await domainService.getVAT();
      const resp = await networkService.autocomplete(null);
      this.networks = this.nessunNetwork.concat(resp.data);
    
    } catch (e) {
      this.$router.back();
    }

    await this.loadTechnicians();
    
    this.isLoading = false;    

    this.modelFormChanged();
  }

  async doSave() {
    this.model.is_configured = this.isConfigured;
    
    await this.$waitFor(
      async () => {
        if (this.isNew) {
          await providerServiceBackofficeService.create(this.model);
        } else {
          await providerServiceBackofficeService.updatePatch(this.model);
        }
        this.modelFormChanged();
        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  async doDelete() {
    this.$waitFor(
      async () => {
        if (this.model.id) {
          await providerServiceBackofficeService.remove(this.model);
        }

        this.$successMessage(this.$t('data_saved_success'));
        this.$router.back();
      },
      this.$t('generic_error')
    );
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  updatePosAttachments(attach: any) {
    this.posAttachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  updateProviderAttachments(attach: any) {
    this.attachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  updateExamAttachments(attach: any) {
    this.examAttachments.push(attach);
    this.$successMessage(this.$t('upload_success'));
  }

  deleteAttachment(attach: IBaseApiAttachmentModel) {
    this.$waitFor(async () => {
      await providerServiceBackofficeService.deleteAttachment(attach.id);
      this.attachments = this.attachments.filter(a => a.id != attach.id);

      this.$successMessage(this.$t('upload_deleted_success'));
    });
  }

  deletePosAttachment(attach: IBaseApiAttachmentModel) {
    this.$waitFor(async () => {
      await providerServiceBackofficeService.deleteAttachment(attach.id);
      this.posAttachments = this.posAttachments.filter(a => a.id != attach.id);

      this.$successMessage(this.$t('upload_deleted_success'));
    });
  }

  deleteExamAttachment(attach: IBaseApiAttachmentModel) {
    this.$waitFor(async () => {
      await providerServiceBackofficeService.deleteAttachment(attach.id);
      this.examAttachments = this.examAttachments.filter(a => a.id != attach.id);

      this.$successMessage(this.$t('upload_deleted_success'));
    });
  }

  get vat() {
    return this.vats.find(x => x.id == this.model.vat_id);
  }

  set vat(value: VAT) {
    this.model.vat_id = value.id;
  }

  get network() {
    return this.networks.find(x => x.id == this.model.network_id);
  }

  set network(value: Network) {
    this.model.network_id = value.id;
  }

  get timeFrom() {
    return shortTime(this.model.time_from);
    // return shortTime(this.model.time_from) || '08:00';
  }

  set timeFrom(value) {
    this.model.time_from = value;
  }

  get timeTo() {
    return shortTime(this.model.time_to);
    // return shortTime(this.model.time_to) || '20:00';
  }

  set timeTo(value) {
    this.model.time_to = value;
  }

  showPosFileUploadDialog() {
    this.displayPosFileUploadDialog = true; 
  }

  showProviderFileUploadDialog() {
    this.displayProviderFileUploadDialog = true; 
  }
  showExamFileUploadDialog() {
    this.displayExamFileUploadDialog = true; 
  }
}