import i18n from "@/i18n";
import { BaseApiModel } from "../common/BaseApiModel";
import { EntityTypeEnum } from "../enums/EntityTypeEnum";
import { Entity } from "./Entity";

export enum CandidacyStatusEnum {
    WAITING = 'WAIT0',
    REJECTED = 'KO000',
    APPROVED = 'OK000'
}

export interface ICandidacyOption {
    name: string;
    code: CandidacyStatusEnum;
}

export const candidacyStatusOptions: ICandidacyOption[] = [
    { name: i18n.global.t('generic.all_plur_male_label'), code: null },
    { name: i18n.global.t('admin.candidacies.detail.waiting_approval_label'), code: CandidacyStatusEnum.WAITING },
    { name: i18n.global.t('admin.candidacies.detail.rejected_label'), code: CandidacyStatusEnum.REJECTED },
    { name: i18n.global.t('admin.candidacies.detail.approved_label'), code: CandidacyStatusEnum.APPROVED }
];

export class Candidacy extends BaseApiModel {
    type: EntityTypeEnum;
    entity_id: number;
    status: CandidacyStatusEnum;

    entity?: Entity;

    get statusOption(): ICandidacyOption | null {
        return candidacyStatusOptions.find(x => x.code == this.status);
    }

    get isApproved(): boolean {
        return this.status == CandidacyStatusEnum.APPROVED;
    }
}