import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "provider_portfolio_services_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "scrollable-datatable" }
const _hoisted_7 = {
  key: 0,
  class: "status-active"
}
const _hoisted_8 = {
  key: 1,
  class: "status-inactive"
}
const _hoisted_9 = { class: "footer" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CurrencyText = _resolveComponent("CurrencyText")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_MyAutocomplete = _resolveComponent("MyAutocomplete")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("section", _hoisted_2, [
        _createVNode("div", null, [
          _createVNode("h1", null, _toDisplayString(_ctx.$t('provider.portfolio.list.title')), 1),
          _createVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('provider.portfolio.list.subtitle')), 1)
        ])
      ]),
      _createVNode("section", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_add_new_label'),
            class: "p-button-success",
            icon: "pi pi-plus",
            iconPos: "right",
            onClick: _ctx.goAdd
          }, null, 8, ["label", "onClick"])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_DynamicTable, {
            ref: "datatable",
            class: "p-mt-3",
            cols: _ctx.cols,
            service: _ctx.service,
            filters: _ctx.filters,
            onEdit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goToDetail($event))),
            customIndexRequestFn: _ctx.customIndexRequest
          }, {
            is_configured: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.is_configured ? _ctx.$t('provider.portfolio.detail.config_status_complete_label') : _ctx.$t('provider.portfolio.detail.config_status_not_complete_label')), 1)
            ]),
            is_active: _withCtx(({ data }) => [
              (data.is_active)
                ? (_openBlock(), _createBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('generic.published')), 1))
                : (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('generic.not_published')), 1))
            ]),
            cost: _withCtx(({ data }) => [
              _createVNode(_component_CurrencyText, {
                value: data.cost
              }, null, 8, ["value"])
            ]),
            "service_name-filter": _withCtx(({ col }) => [
              _withDirectives(_createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.filters.service_name.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.service_name.value = $event)),
                onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
                class: "p-column-filter",
                placeholder: col.header
              }, null, 8, ["modelValue", "onKeydown", "placeholder"]), [
                [
                  _directive_tooltip,
                  'Inserisci qui il nome del servizio per cercarlo nell’elenco sottostante',
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            "service_category_id-filter": _withCtx(({ col }) => [
              _createVNode(_component_MyAutocomplete, {
                type: "service_category",
                modelValue: _ctx.filters.service_category_id.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.service_category_id.value = $event)),
                placeholder: col.header
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            "network_name-filter": _withCtx(({ col }) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.filters.network_name.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters.network_name.value = $event)),
                onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
                class: "p-column-filter",
                placeholder: col.header
              }, null, 8, ["modelValue", "onKeydown", "placeholder"])
            ]),
            "cost-filter": _withCtx(({ col }) => [
              _createVNode(_component_InputText, {
                type: "cost",
                modelValue: _ctx.filters.cost.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.filters.cost.value = $event)),
                onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
                class: "p-column-filter",
                placeholder: col.header
              }, null, 8, ["modelValue", "onKeydown", "placeholder"])
            ]),
            "technicians-filter": _withCtx(({ col }) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.filters.technicians.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.filters.technicians.value = $event)),
                onKeydown: _withKeys(_ctx.applyFilters, ["enter"]),
                class: "p-column-filter",
                placeholder: col.header
              }, null, 8, ["modelValue", "onKeydown", "placeholder"])
            ]),
            "is_configured-filter": _withCtx(({ col }) => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.filters.is_configured.value,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.filters.is_configured.value = $event)),
                options: _ctx.filterConfiguredOptions,
                optionLabel: "name",
                optionValue: "value",
                placeholder: col.header
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            "is_active-filter": _withCtx(({ col }) => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.filters.is_active.value,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.filters.is_active.value = $event)),
                options: _ctx.filterActiveOptions,
                optionLabel: "name",
                optionValue: "value",
                placeholder: col.header
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["cols", "service", "filters", "customIndexRequestFn"])
        ])
      ]),
      _createVNode("section", _hoisted_9, [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_go_back_label'),
          class: "p-button-secondary",
          icon: "pi pi-arrow-left",
          iconPos: "left",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$router.back()))
        }, null, 8, ["label"])
      ])
    ]),
    _createVNode(_component_Dialog, {
      header: _ctx.$t('provider.portfolio.list.btn_add_service_label'),
      visible: _ctx.isAddDialogVisible,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => (_ctx.isAddDialogVisible = $event)),
      modal: true,
      id: "add_provider_portfolio_service_dialog"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('btn_add_new_label'),
          icon: "pi pi-plus",
          iconPos: "right",
          class: "p-button-success",
          onClick: _ctx.doCreateNew,
          disabled: !_ctx.model.service_id || _ctx.requestPending
        }, null, 8, ["label", "onClick", "disabled"])
      ]),
      default: _withCtx(() => [
        (_ctx.isLoadingServices)
          ? (_openBlock(), _createBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('loading_label')), 1))
          : _createCommentVNode("", true),
        (_ctx.allServices.length)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 1,
              modelValue: _ctx.model.service_id,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.model.service_id = $event)),
              optionLabel: "name",
              optionValue: "id",
              options: _ctx.allServices,
              disabled: _ctx.isLoadingServices,
              class: "p-w-100"
            }, null, 8, ["modelValue", "options", "disabled"]))
          : _createCommentVNode("", true),
        (!_ctx.isLoadingServices && !_ctx.allServices.length)
          ? (_openBlock(), _createBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('provider.portfolio.list.msg_full_services_label')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}