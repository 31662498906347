export interface IBaseApiModel {
    id?: number;

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export abstract class BaseApiModel implements IBaseApiModel {
    id?: number;

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface IBaseApiAttachmentModel {
    id?: number;
    filename?: string;
    path_url?: string;
    description?: string;
    type?: string;

    _file?: File;
}