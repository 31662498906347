import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "content card" }
const _hoisted_5 = { class: "p-mb-4" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "p-grid"
}
const _hoisted_8 = { class: "p-field p-col-6" }
const _hoisted_9 = { class: "p-field p-col-6" }
const _hoisted_10 = { class: "p-field p-col-6" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "p-field p-col-6" }
const _hoisted_14 = {
  key: 0,
  class: "status-active"
}
const _hoisted_15 = {
  key: 1,
  class: "status-inactive"
}
const _hoisted_16 = { class: "content card" }
const _hoisted_17 = { class: "p-mb-4" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "p-grid" }
const _hoisted_20 = { class: "p-field p-col-12" }
const _hoisted_21 = { class: "p-field p-col-6" }
const _hoisted_22 = { class: "list-attachments" }
const _hoisted_23 = { class: "list-attachments" }
const _hoisted_24 = { class: "list-attachments" }
const _hoisted_25 = { class: "p-field p-col-6" }
const _hoisted_26 = { class: "p-field p-col-6" }
const _hoisted_27 = { class: "p-field p-col-6" }
const _hoisted_28 = { class: "p-field p-col-6" }
const _hoisted_29 = { class: "p-field p-col-6" }
const _hoisted_30 = { class: "p-field p-col-6" }
const _hoisted_31 = { class: "p-field p-col-6" }
const _hoisted_32 = { class: "p-field p-col-6" }
const _hoisted_33 = { class: "p-field p-col-6" }
const _hoisted_34 = { class: "p-field p-col-12" }
const _hoisted_35 = { class: "p-field-radio" }
const _hoisted_36 = { class: "p-field-radiobutton" }
const _hoisted_37 = { for: "is_active_on" }
const _hoisted_38 = { class: "p-field-radiobutton" }
const _hoisted_39 = { for: "is_active_off" }
const _hoisted_40 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_VField = _resolveComponent("VField")!
  const _component_VError = _resolveComponent("VError")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ServiceDuration = _resolveComponent("ServiceDuration")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_VForm = _resolveComponent("VForm")!
  const _component_FileUploadDialog = _resolveComponent("FileUploadDialog")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_VForm, {
      as: "div",
      id: "service_edit_page",
      class: "page standard vertical-form"
    }, {
      default: _withCtx(({ handleSubmit, isSubmitting, meta: { valid } }) => [
        _createVNode("section", _hoisted_1, [
          _createVNode("div", null, [
            _createVNode("h1", null, _toDisplayString(_ctx.$t('provider.portfolio.detail.title')), 1),
            _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('provider.portfolio.detail.subtitle')), 1)
          ]),
          _createVNode("div", _hoisted_3, [
            (_ctx.model.id)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.$t('btn_delete_label'),
                  class: "p-button-danger",
                  icon: "pi pi-trash",
                  iconPos: "right",
                  disabled: _ctx.requestPending,
                  onClick: _ctx.doDelete
                }, null, 8, ["label", "disabled", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, {
              label: _ctx.$t('btn_save_label'),
              class: "p-button-success",
              icon: "pi pi-save",
              iconPos: "right",
              disabled: !valid || isSubmitting || _ctx.isLoading,
              type: "submit",
              form: "edit_form"
            }, null, 8, ["label", "disabled"])
          ])
        ]),
        _createVNode("section", _hoisted_4, [
          _createVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('generic.info_label')), 1),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t('loading_label')), 1))
            : (_openBlock(), _createBlock("div", _hoisted_7, [
                _createVNode("div", _hoisted_8, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'generic.input_name_label']
                  ]),
                  _createVNode("span", null, _toDisplayString(_ctx.model.service?.name), 1)
                ]),
                _createVNode("div", _hoisted_9, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'provider.portfolio.detail.service_categories_label']
                  ]),
                  _createVNode("span", null, _toDisplayString(_ctx.categoriesLabel), 1)
                ]),
                _createVNode("div", _hoisted_10, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'provider.portfolio.detail.config_status_label']
                  ]),
                  (_ctx.isConfigured)
                    ? (_openBlock(), _createBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('generic.configured_label')), 1))
                    : (_openBlock(), _createBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('generic.not_configured_label')), 1))
                ]),
                _createVNode("div", _hoisted_13, [
                  _withDirectives(_createVNode("label", null, null, 512), [
                    [_directive_t, 'generic.publish_status_label']
                  ]),
                  (_ctx.model.is_active)
                    ? (_openBlock(), _createBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('generic.published')), 1))
                    : (_openBlock(), _createBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('generic.not_published')), 1))
                ])
              ]))
        ]),
        _createVNode("section", _hoisted_16, [
          _createVNode("h2", _hoisted_17, _toDisplayString(_ctx.$t('form_configuration_label')), 1),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock("h3", _hoisted_18, _toDisplayString(_ctx.$t('loading_label')), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode("form", {
            class: "form_content",
            onSubmit: ($event: any) => (handleSubmit($event, _ctx.doSave)),
            id: "edit_form"
          }, [
            _createVNode("div", _hoisted_19, [
              _createVNode("div", _hoisted_20, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('generic.service_policy_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "generic.service_policy_label.tooltip" })
                ]),
                _createVNode(_component_VField, {
                  name: "service_policy",
                  label: "Note",
                  modelValue: _ctx.model.service_policy,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.service_policy = $event)),
                  rules: "required|max:1000"
                }, {
                  default: _withCtx(({ field, meta: { valid } }) => [
                    _createVNode(_component_Textarea, _mergeProps(field, {
                      disabled: isSubmitting,
                      class: [{ 'p-invalid': !valid }, "p-mt-1"],
                      placeholder: _ctx.$t('generic.input_note_label'),
                      rows: "3",
                      cols: "40"
                    }), null, 16, ["disabled", "class", "placeholder"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"]),
                _createVNode(_component_VError, {
                  class: "p-error",
                  name: "service_policy"
                })
              ]),
              _createVNode("div", _hoisted_21, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('pos.attachments_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "generic.attachments_label.tooltip" })
                ]),
                _createVNode("div", _hoisted_22, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.posAttachments, (attach, idx) => {
                    return (_openBlock(), _createBlock("div", {
                      class: ["attachment", attach.type],
                      key: `attach-${idx}`
                    }, [
                      _createVNode("a", {
                        href: `${attach.path_url}?filename=${attach.filename}`,
                        target: "_blank"
                      }, _toDisplayString(attach.description || attach.filename), 9, ["href"]),
                      _createVNode(_component_Button, {
                        class: "p-button-text",
                        icon: "pi pi-times-circle",
                        onClick: ($event: any) => (_ctx.deletePosAttachment(attach)),
                        disabled: _ctx.requestPending
                      }, null, 8, ["onClick", "disabled"])
                    ], 2))
                  }), 128)),
                  _createVNode(_component_Button, {
                    label: _ctx.$t('btn_attachments_label'),
                    class: "p-button-warning",
                    icon: "pi pi-plus",
                    iconPos: "left",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPosFileUploadDialog()))
                  }, null, 8, ["label"])
                ]),
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('generic.attachments_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "generic.attachments_label.tooltip" })
                ]),
                _createVNode("div", _hoisted_23, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.attachments, (attach, idx) => {
                    return (_openBlock(), _createBlock("div", {
                      class: ["attachment", attach.type],
                      key: `attach-${idx}`
                    }, [
                      _createVNode("a", {
                        href: `${attach.path_url}?filename=${attach.filename}`,
                        target: "_blank"
                      }, _toDisplayString(attach.description || attach.filename), 9, ["href"]),
                      _createVNode(_component_Button, {
                        class: "p-button-text",
                        icon: "pi pi-times-circle",
                        onClick: ($event: any) => (_ctx.deleteAttachment(attach)),
                        disabled: _ctx.requestPending
                      }, null, 8, ["onClick", "disabled"])
                    ], 2))
                  }), 128)),
                  _createVNode(_component_Button, {
                    label: _ctx.$t('btn_attachments_label'),
                    class: "p-button-warning",
                    icon: "pi pi-plus",
                    iconPos: "left",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showProviderFileUploadDialog()))
                  }, null, 8, ["label"])
                ]),
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('exam.attachments_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "generic.attachments_label.tooltip" })
                ]),
                _createVNode("div", _hoisted_24, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.examAttachments, (attach, idx) => {
                    return (_openBlock(), _createBlock("div", {
                      class: ["attachment", attach.type],
                      key: `attach-${idx}`
                    }, [
                      _createVNode("a", {
                        href: `${attach.path_url}?filename=${attach.filename}`,
                        target: "_blank"
                      }, _toDisplayString(attach.description || attach.filename), 9, ["href"]),
                      _createVNode(_component_Button, {
                        class: "p-button-text",
                        icon: "pi pi-times-circle",
                        onClick: ($event: any) => (_ctx.deleteAttachment(attach)),
                        disabled: _ctx.requestPending
                      }, null, 8, ["onClick", "disabled"])
                    ], 2))
                  }), 128)),
                  _createVNode(_component_Button, {
                    label: _ctx.$t('btn_attachments_label'),
                    class: "p-button-warning",
                    icon: "pi pi-plus",
                    iconPos: "left",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showExamFileUploadDialog()))
                  }, null, 8, ["label"])
                ])
              ]),
              _createVNode("div", _hoisted_25, [
                _withDirectives(_createVNode("label", null, null, 512), [
                  [_directive_t, 'provider.portfolio.detail.associated_techs_label']
                ]),
                _createVNode(_component_MultiSelect, {
                  options: _ctx.techniciansOptions,
                  optionLabel: (t) => `${t.name} ${t.surname}`,
                  loading: _ctx.isLoadingTechnicians,
                  modelValue: _ctx.model.technicians,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.model.technicians = $event))
                }, null, 8, ["options", "optionLabel", "loading", "modelValue"])
              ]),
              _createVNode("div", _hoisted_26, [
                _withDirectives(_createVNode("label", null, null, 512), [
                  [_directive_t, 'generic.regions_label']
                ]),
                _createVNode("span", null, _toDisplayString(_ctx.regionsLabel), 1)
              ]),
              _createVNode("div", _hoisted_27, [
                _withDirectives(_createVNode("label", null, null, 512), [
                  [_directive_t, 'generic.provinces_label']
                ]),
                _createVNode("span", null, _toDisplayString(_ctx.provincesLabel), 1)
              ]),
              _createVNode("div", _hoisted_28, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('provider.portfolio.detail.cost_vat_exc_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "provider.portfolio.detail.cost_vat_exc_label.tooltip" })
                ]),
                _createVNode(_component_VField, {
                  name: "cost",
                  modelValue: _ctx.model.cost,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.model.cost = $event)),
                  modelModifiers: { number: true },
                  rules: ""
                }, {
                  default: _withCtx(({ field, meta: { dirty, valid } }) => [
                    _createVNode(_component_InputText, _mergeProps(field, {
                      disabled: isSubmitting,
                      step: 0.01,
                      type: "number",
                      class: { 'p-invalid': dirty && !valid }
                    }), null, 16, ["disabled", "step", "class"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"])
              ]),
              _createVNode("div", _hoisted_29, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('generic.vat')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "generic.vat.tooltip" })
                ]),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.vat,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.vat = $event)),
                  options: _ctx.vats,
                  optionLabel: "name"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createVNode("div", _hoisted_30, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('provider.portfolio.detail.from_time_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "provider.portfolio.detail.from_time_label.tooltip" })
                ]),
                _createVNode(_component_VField, {
                  name: "time_from",
                  modelValue: _ctx.timeFrom,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.timeFrom = $event)),
                  rules: ""
                }, {
                  default: _withCtx(({ field, meta: { dirty, valid } }) => [
                    _createVNode(_component_InputText, _mergeProps({ type: "time" }, field, {
                      disabled: isSubmitting,
                      class: { 'p-invalid': dirty && !valid }
                    }), null, 16, ["disabled", "class"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"])
              ]),
              _createVNode("div", _hoisted_31, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('provider.portfolio.detail.to_time_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "provider.portfolio.detail.to_time_label.tooltip" })
                ]),
                _createVNode(_component_VField, {
                  name: "time_to",
                  modelValue: _ctx.timeTo,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.timeTo = $event)),
                  rules: ""
                }, {
                  default: _withCtx(({ field, meta: { dirty, valid } }) => [
                    _createVNode(_component_InputText, _mergeProps({ type: "time" }, field, {
                      disabled: isSubmitting,
                      class: { 'p-invalid': dirty && !valid }
                    }), null, 16, ["disabled", "class"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"])
              ]),
              _createVNode("div", _hoisted_32, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('provider.portfolio.detail.exam_duration_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "provider.portfolio.detail.exam_duration_label.tooltip" })
                ]),
                _createVNode(_component_ServiceDuration, {
                  modelValue: _ctx.model.duration,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.model.duration = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode("div", _hoisted_33, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('pos.profile.networks_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "pos.profile.networks_label.tooltip" })
                ]),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.network,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.network = $event)),
                  options: _ctx.networks,
                  optionLabel: "name"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createVNode("div", _hoisted_34, [
                _createVNode("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('provider.portfolio.detail.publish_label')) + " ", 1),
                  _createVNode(_component_InfoIcon, { text: "provider.portfolio.detail.publish_label.tooltip" })
                ]),
                _createVNode("div", _hoisted_35, [
                  _createVNode("div", _hoisted_36, [
                    _createVNode(_component_RadioButton, {
                      id: "is_active_on",
                      modelValue: _ctx.model.is_active,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.model.is_active = $event)),
                      value: true
                    }, null, 8, ["modelValue"]),
                    _createVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('yes_label')), 1)
                  ]),
                  _createVNode("div", _hoisted_38, [
                    _createVNode(_component_RadioButton, {
                      id: "is_active_off",
                      modelValue: _ctx.model.is_active,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.model.is_active = $event)),
                      value: false
                    }, null, 8, ["modelValue"]),
                    _createVNode("label", _hoisted_39, _toDisplayString(_ctx.$t('no_label')), 1)
                  ])
                ])
              ])
            ])
          ], 40, ["onSubmit"]), [
            [_vShow, !_ctx.isLoading]
          ])
        ]),
        _createVNode("section", _hoisted_40, [
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_go_back_label'),
            class: "p-button-secondary",
            icon: "pi pi-arrow-left",
            iconPos: "left",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$router.back()))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('btn_save_label'),
            class: "p-button-success p-ml-auto",
            icon: "pi pi-save",
            iconPos: "right",
            disabled: !valid || isSubmitting || _ctx.isLoading,
            type: "submit",
            form: "edit_form"
          }, null, 8, ["label", "disabled"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_FileUploadDialog, {
      visible: _ctx.displayPosFileUploadDialog,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_ctx.displayPosFileUploadDialog = $event)),
      object_id: _ctx.model?.id,
      attachment_type: _ctx.pos_attachment_type,
      attachment_id: _ctx.attachment_id,
      onSuccess: _cache[16] || (_cache[16] = ($event: any) => (_ctx.updatePosAttachments($event))),
      maxFileSize: _ctx.maxFileSize
    }, null, 8, ["visible", "object_id", "attachment_type", "attachment_id", "maxFileSize"]),
    _createVNode(_component_FileUploadDialog, {
      visible: _ctx.displayProviderFileUploadDialog,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => (_ctx.displayProviderFileUploadDialog = $event)),
      object_id: _ctx.model?.id,
      attachment_type: _ctx.provider_attachment_type,
      attachment_id: _ctx.attachment_id,
      onSuccess: _cache[18] || (_cache[18] = ($event: any) => (_ctx.updateProviderAttachments($event))),
      maxFileSize: _ctx.maxFileSize
    }, null, 8, ["visible", "object_id", "attachment_type", "attachment_id", "maxFileSize"]),
    _createVNode(_component_FileUploadDialog, {
      visible: _ctx.displayExamFileUploadDialog,
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => (_ctx.displayExamFileUploadDialog = $event)),
      object_id: _ctx.model?.id,
      attachment_type: _ctx.exam_attachment_type,
      attachment_id: _ctx.attachment_id,
      onSuccess: _cache[20] || (_cache[20] = ($event: any) => (_ctx.updateExamAttachments($event))),
      maxFileSize: _ctx.maxFileSize
    }, null, 8, ["visible", "object_id", "attachment_type", "attachment_id", "maxFileSize"])
  ], 64))
}