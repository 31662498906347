import { BaseApiModel, IBaseApiAttachmentModel } from "../common/BaseApiModel";
import { ProviderTechnicianCalendarStatusEnum } from "../enums/ProviderTechnicianCalendarStatusEnum";
import { City, Province, Region, VAT } from "./Domain";
import { Entity } from "./Entity";
import { Network } from "./Network";
import { Order } from "./Order";
import { Service } from "./Service";
import { User } from "./User";

export class Provider extends Entity {
    readonly attachments: IBaseApiAttachmentModel[] = [];
}

export class ProviderService extends BaseApiModel {
    entity_id: number = null;
    service_id: number = null;
    is_active: boolean = false;
    service_policy: string = null;
    cost: number = 0;
    time_from: string = null;
    time_to: string = null;
    is_configured: boolean = false;
    vat_id: number = null;
    duration: number = 0;
    network_id: number = null;
    technicians: ProviderTechnician[];

    readonly service_name: string = null;
    readonly network_name: string = null;
    readonly service_categories_text: string = null;

    readonly entity: Provider = new Provider();
    readonly vat: VAT = null;
    readonly network: Network = null;
    readonly service: Service = null;
    readonly attachments: IBaseApiAttachmentModel[] = [];
    readonly feedback_service: number = null;
}

export class ProviderTechnician extends User {
    services       : Service[] = [];
    regions        : Region[] = [];
    provinces      : Province[] = [];
    cities         : City[] = [];
    provider_data  : ProviderTechnicianData = null;
    providers      : Entity[];
}

export class ProviderTechnicianData {
    is_active: boolean;
    provider_id: number;
}

export class ProviderTechnicianCalendar {
    date_at: string;
    status: ProviderTechnicianCalendarStatusEnum;
    order_id?: number = null;
    order?: Order = null;
}